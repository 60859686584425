import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUser } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    CustomerLogo,
    Language,
} from '@interacta-shared/data-access-configuration';
import { isDefined } from '@interacta-shared/util';
import {
    AppBaseRoute,
    IconMode,
    digitalWorkplace,
} from '@modules/app-routing/routes';
import { NotificationList } from '@modules/core/models/notification-user.model';
import { Theme, ThemeOption } from '@modules/core/models/theme.model';
import { NotificationBellStreamService } from '@modules/core/services/notification-bell-stream.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { UIActions } from '@modules/core/store/UI';
import { HomeSelectorItem } from '@modules/header/models/home-selector.model';
import {
    selectCurrentHomeId,
    selectHomeSelectorItems,
} from '@modules/header/store/header.selectors';
import { ChatStateService } from '@modules/state/services/chat-state.service';
import { LinkDialogStateService } from '@modules/state/services/links-dialog-state.service';
import { StateService } from '@modules/state/services/state.service';
import { UIStateService } from '@modules/state/services/ui-state.service';
import { Store } from '@ngrx/store';
import { Observable, concat, of, timer } from 'rxjs';
import { concatMap, filter, map, mapTo, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'interacta-header[user]',
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    @Input() user?: CurrentUser;
    @Input() customerLogoSrc?: CustomerLogo;
    @Input() iconMode: IconMode = 'menu';
    @Input() currentTheme!: Theme;
    @Input() enableChristmasEasterEgg = false;

    @Output() changeLanguage = new EventEmitter<Language>();
    @Output() changeTheme = new EventEmitter<ThemeOption>();
    @Output() logout = new EventEmitter<void>();
    @Output() openProfile = new EventEmitter<void>();
    @Output() openNotificationsSettings = new EventEmitter<void>();

    availableLanguages!: Language[];
    isUserMenuOpen = false;
    currentLanguage$!: Observable<Language>;
    isSidebarOpen$!: Observable<boolean>;
    showSearchBar$!: Observable<boolean>;
    rootLinkClickable$!: Observable<boolean>;
    currentUserClickable$!: Observable<boolean>;
    showNotifications$!: Observable<boolean>;
    homeSelectorItems$!: Observable<HomeSelectorItem[]>;
    currentHomeId$!: Observable<number | undefined>;
    unreadNotification$!: Observable<number>;
    notifications$!: Observable<NotificationList | undefined>;
    newNotificationToggle$!: Observable<boolean>;
    baseRoute$!: Observable<AppBaseRoute>;
    chatCanShow$!: Observable<boolean>;
    hasUnreadChatNotifications$!: Observable<boolean>;

    constructor(
        private stateService: StateService,
        private uiStateService: UIStateService,
        private configurationService: ConfigurationService,
        private notificationStreamService: NotificationBellStreamService,
        private store: Store<AppState>,
        private router: Router,
        private linkDialogStateService: LinkDialogStateService,
        private chatStateService: ChatStateService,
    ) {}

    ngOnInit(): void {
        this.availableLanguages = this.configurationService.getAppLanguages();
        this.currentLanguage$ =
            this.configurationService.getCurrentLanguageStream();
        this.isSidebarOpen$ = this.stateService.uiState.leftMenuUI
            .onDataChange()
            .pipe(map((menu) => !!menu?.isOpen));

        this.unreadNotification$ =
            this.notificationStreamService.getUnreadNotificationCountStream();
        this.notifications$ =
            this.notificationStreamService.getBufferNotificationsStream();
        this.newNotificationToggle$ = this.notificationStreamService
            .getBufferNotificationsStream()
            .pipe(
                concatMap((_) =>
                    concat(of(true), timer(3000).pipe(mapTo(false))),
                ),
            );

        this.baseRoute$ = this.store.select(AppSelectors.selectRouteState).pipe(
            filter(isDefined),
            map((route) => route.appBaseRoute),
            shareReplay(1),
        );

        this.showSearchBar$ = this.baseRoute$.pipe(
            map(
                (baseRoute) =>
                    ![
                        'post-print',
                        'admin',
                        'admin-v2',
                        'community-detail',
                    ].includes(baseRoute),
            ),
        );

        this.rootLinkClickable$ = this.baseRoute$.pipe(
            map((baseRoute) => 'post-print' !== baseRoute),
        );

        this.currentUserClickable$ = this.baseRoute$.pipe(
            map((baseRoute) => 'post-print' !== baseRoute),
        );

        this.showNotifications$ = this.baseRoute$.pipe(
            map((baseRoute) => 'post-print' !== baseRoute),
        );

        this.homeSelectorItems$ = this.store.select(selectHomeSelectorItems);

        this.currentHomeId$ = this.store.select(selectCurrentHomeId);

        this.chatCanShow$ = this.stateService.chatState.canShow$;

        this.hasUnreadChatNotifications$ =
            this.stateService.chatState.unreadChatStatus$.pipe(
                map((value) => value > 0),
            );
    }

    openNotificationPanel(): void {
        this.store.dispatch(
            UIActions.toggleNotificationPanel({ isOpen: true }),
        );
    }

    openLinksDialog(): void {
        this.linkDialogStateService.open();
    }

    toggleChat(): void {
        if (this.chatStateService.isOpen()) {
            this.chatStateService.toggleCollapse();
        } else {
            this.chatStateService.open();
            this.chatStateService.openChatList();
        }
    }

    toggleMenu(): void {
        const currState =
            this.stateService.uiState.leftMenuUI.staticData()?.isOpen;
        const nextState = !currState;
        this.uiStateService.changeLeftMenuOpen(nextState);
    }

    changeCurrentHome(homeId: number) {
        this.router.navigate(['/', digitalWorkplace, homeId]);
    }
}
