import { Injectable, inject } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { StateManagerService } from '@modules/state/services/state-manager.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { getMappedLocation } from '../helpers/legacy-path-mappings.utils';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
    providedIn: 'root',
})
export class BootService {
    private readonly googleAnalyticsService = inject(GoogleAnalyticsService);

    constructor(
        private configurationService: ConfigurationService,
        private stateManagerService: StateManagerService,
    ) {}

    public async initApp(): Promise<void> {
        if (!this.legacyPathRewrite()) {
            return firstValueFrom(
                forkJoin([
                    this.configurationService.updateTimezones(),
                    this.configurationService.updateEnvironmentInfo(),
                ]).pipe(
                    tap(([_, environmentInfo]) => {
                        this.initGoogleAnalytics(
                            environmentInfo.googleAnalyticsClientId,
                            environmentInfo.plantId,
                            environmentInfo.plantBaseUrl,
                        );
                    }),
                    concatMap(([_, environmentInfo]) =>
                        this.stateManagerService.validateCache(
                            environmentInfo.buildInfo,
                        ),
                    ),
                ),
            );
        }
    }

    private initGoogleAnalytics(
        googleAnalyticsClientId: string | null,
        plantId: number,
        plantBaseUrl: string,
    ) {
        if (googleAnalyticsClientId) {
            this.googleAnalyticsService.init(
                googleAnalyticsClientId,
                plantId,
                plantBaseUrl,
            );
        } else {
            console.warn('googleAnalyticsClientId NON provided');
        }
    }

    private legacyPathRewrite(): boolean {
        const originalLocation = window.location.href;

        const mappedLocation = getMappedLocation(originalLocation);

        if (mappedLocation !== originalLocation) {
            window.location.href = mappedLocation;
            return true;
        }
        return false;
    }
}
