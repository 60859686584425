<interacta-user-menu-title
    [label]="'USER_MENU.SELECT_LANGUAGE' | translate"
    (back)="back.emit()"
></interacta-user-menu-title>

<interacta-user-menu-separator></interacta-user-menu-separator>

<div class="flex flex-col items-center">
    <button
        *ngFor="let language of availableLanguages"
        class="w-full"
        interacta-user-menu-button
        type="button"
        width="full"
        (click)="changeLanguage.emit(language)"
    >
        <div
            [ngClass]="{
                'text-text-primary': language.code === currentLanguage.code
            }"
        >
            {{ language.description }}
        </div>
    </button>
</div>
