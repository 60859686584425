<ng-container
    *ngIf="{ simpleTip: simpleTip$ | async, proTip: proTip$ | async } as $"
>
    <div
        *ngIf="$.simpleTip || $.proTip"
        class="bg-surface-A-reverse shadow-tip w-352 xl:w-440 z-overlay-tip fixed bottom-48 left-0 right-0 mx-auto flex min-h-64 flex-col justify-center rounded-2xl p-16"
        @tip
    >
        <interacta-simple-tip
            [tip]="$.simpleTip"
            (closeTip)="close()"
            (forceReload)="forceReload()"
        ></interacta-simple-tip>
        <interacta-pro-tip
            [tip]="$.proTip"
            (actionClicked)="actionClicked($event)"
            (closeTip)="close($.proTip?.closeCallback)"
        ></interacta-pro-tip>
    </div>
</ng-container>
