<ng-container
    *ngIf="{
        hashtag: hashtag$ | async,
        community: community$ | async,
        visible: (visible$ | async) ?? false
    } as $"
>
    <ng-container *ngIf="$.visible">
        <interacta-dialog
            size="regular"
            [isOpen]="$.visible"
            (closing)="close()"
        >
            <!-- title -->

            <div
                *ngIf="$.hashtag && $.community; else loader"
                class="typo-t5"
                title
            >
                #{{ $.hashtag.name }}
            </div>

            <!-- content -->

            <div
                *ngIf="
                    $.hashtag && $.community?.metadata?.hashTagEnabled;
                    else hashtagsDisabled
                "
                content
            >
                <div class="typo-sm mb-16">
                    {{
                        $.hashtag.count
                            | i18nPlural
                                : {
                                      '=1': 'HASHTAG_INFO.COUNT_IN_COMMUNITY_SING',
                                      other: 'HASHTAG_INFO.COUNT_IN_COMMUNITY_PLUR'
                                  }
                            | translate
                                : {
                                      count: $.hashtag.count,
                                      community: $.community?.name
                                  }
                    }}
                </div>

                <!-- follow/unfollow button -->

                <button
                    *ngIf="!$.hashtag.deleted"
                    class="mb-16"
                    icon="bell"
                    interacta-button
                    type="button"
                    [active]="$.hashtag.followedByMe ?? false"
                    [appearance]="$.hashtag.followedByMe ? 'border' : 'fill'"
                    [label]="
                        ($.hashtag.followedByMe
                            ? 'HASHTAG_INFO.ALREADY_FOLLOWING'
                            : 'HASHTAG_INFO.FOLLOW'
                        ) | translate
                    "
                    (click)="$event.stopPropagation(); toggleFollow($.hashtag)"
                ></button>

                <interacta-separator
                    class="mb-8 block"
                    thickness="strong"
                ></interacta-separator>

                <!-- search type selection -->

                <div class="typo-sm-bold">
                    {{ 'HASHTAG_INFO.SEARCH' | translate }}
                </div>

                <interacta-radio-group
                    *ngIf="
                        $.community?.metadata?.attachmentEnabled;
                        else attachmentsNotEnabled
                    "
                    [formControl]="searchType"
                >
                    <interacta-radio-button
                        class="typo-sm mt-12"
                        [value]="'post'"
                    >
                        {{ 'HASHTAG_INFO.SEARCH_TYPE.POST' | translate }}
                    </interacta-radio-button>
                    <interacta-radio-button
                        class="typo-sm mt-12"
                        [value]="'attachment'"
                    >
                        {{ 'HASHTAG_INFO.SEARCH_TYPE.ATTACHMENT' | translate }}
                    </interacta-radio-button>
                    <interacta-radio-button
                        class="typo-sm mt-12"
                        [value]="'both'"
                    >
                        {{ 'HASHTAG_INFO.SEARCH_TYPE.BOTH' | translate }}
                    </interacta-radio-button>
                </interacta-radio-group>
                <ng-template #attachmentsNotEnabled>
                    <interacta-checkbox-group [formControl]="searchType">
                        <interacta-checkbox
                            class="typo-sm mt-12"
                            [value]="'post'"
                        >
                            {{ 'HASHTAG_INFO.SEARCH_TYPE.POST' | translate }}
                        </interacta-checkbox>
                    </interacta-checkbox-group>
                </ng-template>

                <!-- apply / cancel buttons -->

                <interacta-dialog-buttons>
                    <button
                        appearance="ghost"
                        interacta-dialog-button
                        type="button"
                        [bgColor]="'surface-A'"
                        [label]="
                            'BUTTON.LABEL_BUTTON_CANCEL'
                                | translate: { hashtag: $.hashtag.name }
                        "
                        (click)="close()"
                    ></button>
                    <button
                        appearance="fill"
                        interacta-dialog-button
                        type="button"
                        [bgColor]="'primary'"
                        [label]="'BUTTON.LABEL_BUTTON_APPLY' | translate"
                        (click)="apply($.hashtag, searchType.value)"
                    ></button>
                </interacta-dialog-buttons>
            </div>

            <!-- content in case of disabled hashtags in community -->

            <ng-template #hashtagsDisabled>
                <div *ngIf="$.community" content>
                    {{ 'HASHTAG_INFO.DISABLED_COMMUNITY' | translate }}
                </div>
                <interacta-dialog-buttons *ngIf="$.community">
                    <button
                        appearance="fill"
                        interacta-dialog-button
                        type="button"
                        [bgColor]="'primary'"
                        [label]="'HASHTAG_INFO.UNDERSTOOD' | translate"
                        (click)="close()"
                    ></button>
                </interacta-dialog-buttons>
            </ng-template>

            <!-- loader -->

            <ng-template #loader>
                <div class="h-200 m-auto flex items-center" title>
                    <interacta-loader [size]="'large'"></interacta-loader>
                </div>
            </ng-template>
        </interacta-dialog>
    </ng-container>
</ng-container>
