import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';

registerLicense(
    'Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxfeXRTRmleWEx1V0c=',
);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
