import { getRandomNumber } from '@interacta-shared/util';
import { ProTipData, ProTipImage, TipData, TipSeverity } from './tip.model';

const PRO_TIP_IMAGES: ProTipImage[] = ['reading', 'gardening'];

export function isProTip<A, P>(tip: TipData): tip is ProTipData<A, P> {
    return tip.severity === TipSeverity.PRO;
}

export function getRandomProTipImage(): ProTipImage {
    const idx = getRandomNumber(0, PRO_TIP_IMAGES.length);
    return PRO_TIP_IMAGES[idx];
}

export function asString(message: unknown): string | undefined {
    return typeof message === 'string' ? message : undefined;
}
