<div class="relative flex w-full items-center">
    <div class="typo-sm-bold absolute w-full text-center">
        <div class="mx-36 break-words">{{ label }}</div>
    </div>

    <button
        icon="chevron-small-left"
        interacta-icon-button
        size="large"
        type="button"
        (click)="back.emit()"
    ></button>
</div>
