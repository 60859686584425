<interacta-user-menu-title
    [label]="'USER_MENU.SELECT_THEME' | translate"
    (back)="back.emit()"
></interacta-user-menu-title>

<interacta-user-menu-separator></interacta-user-menu-separator>

<div class="flex items-start justify-center space-x-8 py-16">
    <button
        icon="sun"
        interacta-button-menu-vertical
        type="button"
        [label]="'THEME.LIGHT' | translate"
        [selected]="!currentTheme.isSystem && currentTheme.mode === 'light'"
        (click)="changeTheme.emit('light')"
    ></button>
    <div class="flex flex-col items-center">
        <button
            icon="moon"
            interacta-button-menu-vertical
            type="button"
            [label]="'THEME.DARK' | translate"
            [selected]="!currentTheme.isSystem && currentTheme.mode === 'dark'"
            (click)="changeTheme.emit('dark')"
        ></button>
    </div>

    <button
        icon="half-sun"
        interacta-button-menu-vertical
        type="button"
        [label]="'THEME.DEFAULT' | translate"
        [selected]="currentTheme.isSystem"
        (click)="changeTheme.emit('system')"
    ></button>
</div>
