<ng-container
    *ngIf="{
        carousel: carouselService.getCarousel$ | async
    } as $"
>
    <div
        *ngIf="$.carousel && canShowCarousel"
        class="bg-transparent-black-not-black-95 absolute right-0 top-0 z-50 flex h-full w-full items-center justify-center"
        [@carousel]
    >
        <div class="relative h-5/6 w-2/3 overflow-hidden rounded-xl">
            <interacta-carousel-slide
                *ngFor="let slide of $.carousel.slides; let index = index"
                class="absolute h-full w-full"
                [@slideIn]="
                    currentIndex === index
                        ? 'in'
                        : index > currentIndex
                          ? 'outright'
                          : 'outleft'
                "
                [config]="$.carousel.config"
                [index]="index"
                [ngClass]="{
                    'z-10': currentIndex === index
                }"
                [slide]="slide"
                [totalItems]="$.carousel.slides.length"
                (closeCarousel)="closeCarousel()"
                (nextStep)="nextStep($event)"
            >
            </interacta-carousel-slide>
        </div>
    </div>
</ng-container>
