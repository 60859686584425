<div class="flex h-full items-center gap-x-4">
    @if (showCurrentChat) {
        @if (profileImg) {
            <interacta-image
                [alt]="'CHAT_EMBEDDED.CHAT_PANEL.ALT_LOGO' | translate"
                [classes]="'size-32 rounded-full object-cover'"
                [src]="profileImg"
            />
        } @else {
            <div
                class="flex size-32 items-center justify-center rounded-full object-cover {{
                    getRandomColor(currentChatStatus.avatarColorHash)
                }}"
            >
                <p class="text-text-on-avatar typo-sm-bold">
                    {{ currentChatStatus.avatarInitials }}
                </p>
            </div>
        }
        <p class="typo-sm-bold text-text">
            {{ chatTitle }}
        </p>
    } @else {
        <interacta-image
            [alt]="'CHAT_EMBEDDED.CHAT_PANEL.ALT_LOGO' | translate"
            [classes]="'size-24'"
            [src]="chatLogo"
        />
        <h5 class="typo-t5 text-text lowercase">{{ chatTitle }}</h5>
    }
    <p
        class="typo-sm-bold text-text-primary transition-opacity"
        [ngClass]="{
            'opacity-0': unreadChatOrMessages <= 0 || !isCollapsed
        }"
    >
        ({{ unreadChatOrMessages }})
    </p>
</div>
