<div *ngIf="tip" class="flex items-center gap-16">
    <ng-container [ngSwitch]="tip.severity">
        <interacta-icon
            *ngSwitchCase="TipSeverity.SUCCESS"
            class="text-system-success h-24 w-24 shrink-0"
            [icon]="'check'"
        ></interacta-icon>
        <interacta-icon
            *ngSwitchCase="TipSeverity.INFO"
            class="text-text-link h-24 w-24 shrink-0"
            [icon]="'info'"
        ></interacta-icon>
        <interacta-icon
            *ngSwitchCase="TipSeverity.WARN"
            class="text-system-warning h-24 w-24 shrink-0"
            [icon]="'warning'"
        ></interacta-icon>
        <interacta-icon
            *ngSwitchCase="TipSeverity.ERROR"
            class="text-text-error h-24 w-24 shrink-0"
            [icon]="'warning'"
        ></interacta-icon>
    </ng-container>
    <div
        *ngIf="tip.message | apply: asString as message; else deltaView"
        class="typo-sm text-text-reverse overflow-auto whitespace-pre-line break-words"
    >
        {{ message | translate: tip.translateParams }}
    </div>
    <ng-template #deltaView>
        <interacta-delta-view
            class="typo-sm text-text-reverse min-w-0"
            [delta]="tip.message"
        ></interacta-delta-view>
    </ng-template>
    <button
        *ngIf="tip.closeBehavior !== 'forceReload'; else forceReloadButton"
        class="text-text-reverse ml-auto shrink-0"
        interacta-icon-button
        type="button"
        [bgColor]="'reverse-transparent'"
        [icon]="'close'"
        (click)="closeTip.emit()"
    ></button>
    <ng-template #forceReloadButton>
        <button
            appearance="ghost"
            class="ml-auto shrink-0"
            interacta-dialog-button
            type="button"
            [active]="true"
            [label]="'SHARED.TIPS.FORCE_RELOAD' | translate"
            (click)="forceReload.emit()"
        ></button>
    </ng-template>
</div>
