<ng-container *ngIf="data$ | async as $">
    <interacta-dialog
        [contentOverflowAuto]="false"
        [isOpen]="$.isOpen"
        [size]="'small'"
        (closed)="close()"
    >
        <h6 class="typo-t6" title>
            {{ 'GROUP_DETAILS.GROUP_DETAILS' | translate }}
        </h6>

        @if (!$.isFetching && $.group) {
            <div class="flex flex-col gap-y-8" subtitle>
                <div class="flex items-center gap-x-16">
                    <div
                        class="flex items-center justify-center rounded-full size-64 {{
                            $.group.id | apply: getRandomColor
                        }}"
                    >
                        <interacta-icon
                            class="text-pure-black size-44"
                            [icon]="'people'"
                        />
                    </div>

                    <div class="flex flex-col items-start gap-4">
                        <p class="typo-medium">
                            {{ $.group.fullName }}
                        </p>

                        <p
                            *ngIf="!$.group.deleted"
                            class="typo-xs-bold-uppercase text-gray-700"
                        >
                            {{
                                $.group.membersCount
                                    | i18nPlural
                                        : {
                                              '=1': 'GROUP_DETAILS.NUMBER_OF_MEMBERS_SINGULAR',
                                              other: 'GROUP_DETAILS.NUMBER_OF_MEMBERS_PLURAL'
                                          }
                                    | translate: { count: $.group.membersCount }
                            }}
                        </p>

                        @if ($.group.tags && $.group.tags.length) {
                            <div
                                class="max-w-240 flex gap-x-4 overflow-hidden"
                                injShowMoreChildren
                                (showMore)="remainingTags = $event"
                            >
                                <interacta-group-tag
                                    *ngFor="let tag of $.group.tags"
                                    injShowMoreItem
                                    [label]="tag.name"
                                    [size]="'small'"
                                />
                                <span *ngIf="remainingTags" class="typo-xs">{{
                                    '+' + remainingTags
                                }}</span>
                            </div>
                        }
                    </div>
                </div>

                <p
                    *ngIf="$.group.description"
                    class="typo-sm line-clamp-3 whitespace-pre-line italic"
                >
                    {{ $.group.description }}
                </p>

                <interacta-separator class="mb-16" />
            </div>
        }

        @if (
            $.group &&
            ($.group.membersCount ?? 0) > N_MEMBERS_TO_SHOW &&
            showMoreMembers
        ) {
            <interacta-input-search
                class="mb-16"
                search
                [control]="searchMemberControl"
                [iconPosition]="'right'"
            />
        }

        @if (!$.isFetching && $.group) {
            <div
                *ngIf="members$ | async as members"
                class="min-h-360 flex flex-col gap-y-16 overflow-hidden"
                content
            >
                <!-- MEMBERS PREVIEW -->
                @if (members) {
                    @if (showMoreMembers) {
                        <div
                            class="h-full overflow-y-auto py-4"
                            interactaScrollTracker
                            (scrolled)="onScroll($event, members.nextPageToken)"
                        >
                            @if (
                                members.isFetching &&
                                members.nextPageToken.tag === 'firstLoading'
                            ) {
                                <interacta-member-list-placeholder />
                            } @else {
                                <div
                                    *ngIf="members.list.length"
                                    class="flex grow flex-col gap-y-16"
                                >
                                    @for (
                                        member of members.list;
                                        track member.id
                                    ) {
                                        <ng-template
                                            *ngTemplateOutlet="
                                                memberTemplate;
                                                context: {
                                                    member
                                                }
                                            "
                                        />
                                    }
                                </div>
                                <interacta-load-more
                                    *ngIf="
                                        members.nextPageToken.tag ===
                                        'regularLoading'
                                    "
                                    class="flex items-center justify-center py-8"
                                    size="regular"
                                    [isLoading]="members.isFetching"
                                    [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
                                    (loadMore)="
                                        fetchNextMembers(members.nextPageToken)
                                    "
                                />
                            }
                        </div>
                    } @else {
                        @if (members.isFetching) {
                            <interacta-member-list-placeholder />
                        } @else {
                            <p *ngIf="$.group.deleted" class="typo-sm">
                                {{ 'GROUP_DETAILS.DELETED_GROUP' | translate }}
                            </p>

                            <div
                                *ngIf="$.group.membersCount"
                                class="flex h-full flex-col gap-y-16 overflow-y-auto pe-8"
                            >
                                @for (member of members.list; track member.id) {
                                    <div
                                        class="flex items-center justify-between"
                                    >
                                        <ng-template
                                            *ngTemplateOutlet="
                                                memberTemplate;
                                                context: {
                                                    member
                                                }
                                            "
                                        />
                                        <button
                                            *ngIf="
                                                $last && remainingMembers > 0
                                            "
                                            class="typo-sm text-palette-secondary"
                                            type="button"
                                            (click)="loadMoreMembers()"
                                        >
                                            {{
                                                remainingMembers
                                                    | i18nPlural
                                                        : {
                                                              '=1': 'GROUP_DETAILS.OTHER_MEMBERS_SINGULAR',
                                                              other: 'GROUP_DETAILS.OTHER_MEMBERS_PLURAL'
                                                          }
                                                    | translate
                                                        : {
                                                              remainingMembers
                                                          }
                                            }}
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                    }
                }
            </div>
        } @else {
            <div class="h-200 m-auto flex items-center" content>
                <interacta-loader [size]="'large'" />
            </div>
        }

        <interacta-dialog-buttons
            *ngIf="$.group && showEditButton()"
            [showSeparator]="true"
        >
            <button
                interacta-dialog-button
                type="button"
                [active]="true"
                [appearance]="'ghost'"
                [bgColor]="'primary'"
                [label]="'GROUP_DETAILS.EDIT_GROUP' | translate"
                (click)="edit($.group.id)"
            ></button>
        </interacta-dialog-buttons>
    </interacta-dialog>

    <ng-template #memberTemplate let-member="member">
        @if (isAdminSection()) {
            <interacta-admin-v2-member [member]="member" />
        } @else {
            <interacta-member [member]="member" [size]="'small'" />
        }
    </ng-template>
</ng-container>
