<div *ngIf="tip" class="text-text-reverse flex items-center gap-16">
    <img
        *ngIf="imageSrc"
        alt="file image"
        class="h-80 w-80 shrink-0"
        [src]="imageSrc"
    />
    <div class="min-w-0 grow break-words">
        <h6
            *ngIf="tip.title"
            class="typo-t6 mb-4"
            [innerHTML]="tip.title | translate: tip.translateParams | safeHtml"
        ></h6>
        <div
            class="flex"
            [ngClass]="{
                'flex-col': tip && tip.direction === 'vertical',
                'w-full justify-between': tip.direction === 'horizontal'
            }"
        >
            <div
                *ngIf="tip.message | apply: asString as message; deltaView"
                class="typo-sm whitespace-pre-line"
                [innerHtml]="message | translate: tip.translateParams"
            ></div>

            <ng-template #deltaView>
                <interacta-delta-view
                    class="typo-sm text-text-reverse min-w-0 whitespace-pre-line break-all"
                    [delta]="tip.message"
                ></interacta-delta-view>
            </ng-template>

            <div
                *ngIf="tip.actionCallbacks.length"
                class="mt-4 flex flex-wrap gap-x-16 gap-y-4"
            >
                <button
                    *ngFor="let actionCallback of tip.actionCallbacks"
                    class="text-text-primary typo-xs-bold-uppercase overflow-hidden whitespace-nowrap"
                    type="button"
                    (click)="actionClicked.emit(actionCallback)"
                >
                    {{ actionCallback.action.label | translate }}
                </button>
            </div>
        </div>
    </div>
    <button
        *ngIf="!tip?.hideCloseButton"
        class="text-text-reverse ml-auto shrink-0"
        interacta-icon-button
        type="button"
        [bgColor]="'reverse-transparent'"
        [icon]="'close'"
        (click)="closeTip.emit()"
    ></button>
</div>
