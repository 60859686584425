import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { AuthService, CurrentUser } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    CustomerLogo,
    Language,
} from '@interacta-shared/data-access-configuration';
import { TipService, openProTip } from '@interacta-shared/feature-tip';
import { ConfirmDialogService } from '@interacta-shared/ui-common';
import { filterMap, isDefined } from '@interacta-shared/util';
import { HistoryRoutingService } from '@interacta-shared/util-common';
import {
    AppBaseRoute,
    IconMode,
    getIconMode,
    login,
    streaming,
    unsubscribe,
    verifyPrivateEmail,
} from '@modules/app-routing/routes';
import { ICommunity } from '@modules/communities/models/communities.model';
import { isChristmasTime } from '@modules/core/helpers/generic.utils';
import {
    Theme,
    ThemeOption,
    systemTheme,
} from '@modules/core/models/theme.model';
import { GoogleAnalyticsService } from '@modules/core/services/google-analytics.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { UIActions } from '@modules/core/store/UI';
import * as UISelectors from '@modules/core/store/UI/ui.selector';
import { EProfileOrigin } from '@modules/profile/models/profile-origin.enum';
import { isSidebarShown } from '@modules/sidebar/models/sidebar.utils';
import { LeftMenuUI } from '@modules/state/models/ui-state.model';
import { CatalogsStateService } from '@modules/state/services/catalogs-state.service';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { StateService } from '@modules/state/services/state.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { notificationSettings } from './modules/app-routing/routes';

const noHeaderBaseRoutes: AppBaseRoute[] = [
    login,
    'error',
    verifyPrivateEmail,
    unsubscribe,
    streaming,
];

const noCarousel: AppBaseRoute[] = [login, 'error', verifyPrivateEmail];

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    user$: Observable<CurrentUser>;
    theme$!: Observable<Theme>;
    logo!: CustomerLogo;
    iconMode: IconMode = 'menu';
    showHeader$!: Observable<boolean>;
    showSidebar$!: Observable<boolean>;
    showAIButton$!: Observable<boolean>;
    canShowCarousel$!: Observable<boolean>;
    community?: ICommunity | null;
    leftMenuUI$: Observable<LeftMenuUI | undefined>;
    enableChristmasEasterEgg = false;

    constructor(
        private historyRoutingService: HistoryRoutingService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService,
        private configurationService: ConfigurationService,
        private store: Store<AppState>,
        private confirmDialogService: ConfirmDialogService,
        private communitiesStateService: CommunitiesStateService,
        private catalogsStateService: CatalogsStateService,
        private tipService: TipService,
        public appState: StateService,
    ) {
        this.leftMenuUI$ = appState.uiState.leftMenuUI.onDataChange();
        this.enableChristmasEasterEgg = isChristmasTime();
        this.user$ = this.authService.currentUserData$.pipe(filter(isDefined));
    }

    ngOnInit(): void {
        this.logo = this.configurationService.getCustomerLogoUrl();

        this.theme$ = this.store
            .select(UISelectors.theme)
            .pipe(filterMap((theme) => theme ?? undefined));

        this.historyRoutingService.loadRouting();

        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
                mergeMap((_) => this.getRouteData(this.activatedRoute)),
                filter((routeData) => routeData.id != null),
            )
            .subscribe(({ id }) => {
                this.googleAnalyticsService.sendScreenView(id);
            });

        this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                filter(isDefined),
                map((route) => getIconMode(route.appRoute)),
            )
            .subscribe((icon) => (this.iconMode = icon));

        this.showHeader$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                map(
                    (route) =>
                        isDefined(route) &&
                        (!route.appBaseRoute ||
                            !noHeaderBaseRoutes.includes(route.appBaseRoute)),
                ),
            );

        this.showSidebar$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                map(
                    (route) =>
                        isDefined(route) &&
                        !!route.appRoute &&
                        isSidebarShown(route.appRoute),
                ),
            );

        this.canShowCarousel$ = this.store
            .select(AppSelectors.selectRouteState)
            .pipe(
                map(
                    (route) =>
                        isDefined(route) &&
                        (!route.appBaseRoute ||
                            !noCarousel.includes(route.appBaseRoute)),
                ),
            );

        this.configurationService
            .checkUpdateVersionRequired()
            .pipe(filter((isNewVersion) => isNewVersion))
            .subscribe(() =>
                this.tipService.info('TIPS.NEW_APP_VERSION', 'forceReload'),
            );
    }

    changeTheme(mode: ThemeOption): void {
        this.store.dispatch(
            UIActions.changeTheme({
                theme:
                    mode === 'system'
                        ? systemTheme()
                        : { isSystem: false, mode },
            }),
        );
    }

    changeLanguage(lang: Language): void {
        const currentLang = this.configurationService.getCurrentLanguage();
        if (lang?.code !== currentLang.code) {
            this.translate
                .use(lang.code)
                .pipe(
                    switchMap((_) =>
                        this.confirmDialogService.open({
                            title: 'DIALOG_BASE.LABEL_TITLE_LANGUAGE_RELOAD',
                            description:
                                'DIALOG_BASE.LABEL_MESSAGE_LANGUAGE_RELOAD',
                        }),
                    ),
                    switchMap((confirm) =>
                        confirm
                            ? of(confirm)
                            : this.translate
                                  .use(currentLang.code)
                                  .pipe(switchMap(() => EMPTY)),
                    ),
                    switchMap((_) => [
                        this.communitiesStateService.clearCommunitiesCache(),
                        this.catalogsStateService.clearCatalogsCache(),
                    ]),
                )
                .subscribe(() => window.location.reload());
        }
    }

    logout(): void {
        this.authService.signOut().subscribe(() => {
            this.store.dispatch(
                openProTip({
                    title: 'SHARED.LOGOUT_TIP.TITLE',
                    message: 'SHARED.LOGOUT_TIP.MESSAGE',
                }),
            );
        });
    }

    openProfile(): void {
        const userId = this.authService.getCurrentUserData()?.id;
        if (userId) {
            this.router.navigate([`/profile/${userId}`], {
                queryParams: { origin: EProfileOrigin.PEOPLE },
            });
        }
    }

    openNotificationsSettings(): void {
        this.router.navigate([notificationSettings]);
    }

    private getRouteData(route: ActivatedRoute): Observable<Data> {
        let child = route;
        while (child.firstChild) {
            child = child.firstChild;
        }
        return child.data;
    }
}
