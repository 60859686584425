<ng-container
    *ngIf="{
        notificationPanelUi: notificationPanelUi$ | async,
        unreadNotification: (unreadNotification$ | async) ?? 0,
        notifications: notifications$ | async
    } as $"
>
    <div
        *ngIf="
            $.notificationPanelUi &&
            $.notificationPanelUi.isOpen &&
            $.notifications
        "
        cdkTrapFocus
        cdkTrapFocusAutoCapture
        class="bg-transparent-black-not-black-95 fixed inset-0 flex"
        role="dialog"
        @panel
    >
        <div
            class="relative flex h-full grow flex-col items-end py-16 pr-12"
            (click)="close()"
        >
            <div
                class="bg-surface-B relative mb-8 flex shrink-0 items-center justify-center rounded-full p-8"
            >
                <interacta-icon
                    class="block h-24 w-24"
                    [attr.aria-label]="'NOTIFICATIONS.OPEN' | translate"
                    [icon]="'bell'"
                    [ngClass]="{
                        'text-text-primary animate-swing origin-top':
                            $.unreadNotification > 0
                    }"
                ></interacta-icon>
                <div
                    *ngIf="$.unreadNotification > 0"
                    class="bg-surface-primary absolute right-4 top-8 h-8 w-8 rounded-full"
                ></div>
            </div>

            <div
                class="w-384 bg-surface-A text-text flex min-h-0 grow origin-top-right flex-col space-y-12 rounded-2xl px-12 pt-20"
                id="menu"
                (click)="$event.stopPropagation()"
            >
                <div class="flex items-center justify-between">
                    <span class="typo-t5">{{
                        'NOTIFICATIONS.TITLE' | translate
                    }}</span>
                    <interacta-toggle
                        class="typo-sm"
                        [checked]="$.notificationPanelUi.onlyUnread"
                        [label]="
                            'NOTIFICATIONS.SHOW_NOT_READ_FLAG'
                                | translate
                                    : {
                                          count: $.unreadNotification
                                      }
                        "
                        [labelPosition]="'before'"
                        (toggle)="
                            toggleShowNotRead(!$.notificationPanelUi.onlyUnread)
                        "
                    ></interacta-toggle>
                </div>

                <button
                    *ngIf="$.unreadNotification > 0"
                    class="typo-sm text-text-primary ml-auto"
                    type="button"
                    (click)="
                        markAllNotificationEventsAsRead();
                        $event.stopPropagation()
                    "
                >
                    {{ 'NOTIFICATIONS.MARK_ALL_AS_READ' | translate }}
                </button>

                <div
                    *ngIf="$.notifications.list as list"
                    #scroll
                    class="invisible flex min-h-0 grow flex-col hover:visible"
                >
                    <div class="visible">
                        <virtual-scroller
                            [enableUnequalChildrenSizes]="false"
                            [items]="list"
                            [parentScroll]="scroll"
                            (vsEnd)="nextPage($event, $.notifications)"
                            (vsUpdate)="displayedNotifications = $event"
                        >
                            <div
                                *ngFor="
                                    let item of displayedNotifications;
                                    let last = last;
                                    trackBy: trackById
                                "
                                class="flex flex-col space-y-12 pb-12"
                            >
                                <interacta-notification-row
                                    [notification]="item"
                                    (clickOnNotification)="
                                        clickOnNotification($event)
                                    "
                                    (markAsRead)="
                                        markNotificationEventsAsRead(
                                            item,
                                            false
                                        )
                                    "
                                    (viewAttachments)="viewAttachments(item)"
                                >
                                </interacta-notification-row>
                                <interacta-separator
                                    *ngIf="!last"
                                    [thickness]="'soft'"
                                ></interacta-separator>
                            </div>
                            <interacta-load-more
                                *ngIf="
                                    $.notifications.nextPageToken.tag !==
                                        'lastLoading' &&
                                    (displayedNotifications.length > 0 ||
                                        $.notifications.isFetching)
                                "
                                class="flex items-center justify-center py-8"
                                [isLoading]="$.notifications.isFetching"
                                [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
                                [size]="'regular'"
                                (loadMore)="loadMorePastNotification()"
                            ></interacta-load-more>
                        </virtual-scroller>

                        <interacta-no-result
                            *ngIf="
                                $.notifications.nextPageToken.tag !==
                                    'firstLoading' &&
                                !$.notifications.isFetching &&
                                !$.notifications.list.length
                            "
                            [imageName]="'empty-page'"
                            [subtitle]="
                                ($.notificationPanelUi.onlyUnread
                                    ? 'NOTIFICATIONS.NO_UNREAD_NOTIFICATIONS'
                                    : 'NOTIFICATIONS.EMPTY_PAGE'
                                ) | translate
                            "
                        >
                        </interacta-no-result>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
