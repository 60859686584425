import { Location } from '@angular/common';
import { ComponentRef, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    RouteReuseStrategy,
} from '@angular/router';
import { routeToUrl } from '@interacta-shared/util-common';
import { AppRoute, urlToAppRoute } from '@modules/app-routing/routes';

interface StoredRoute {
    route: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
    /** `shouldAttach()` gets called twice per route. This flag is used to remember
     * that we already checked (and resetted to false) the `isBack` flag for this route. */
    isBacking: boolean;
}

interface ComponentRefContainer extends DetachedRouteHandle {
    componentRef: ComponentRef<unknown>;
}

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    storedRouteHandles = new Map<AppRoute, StoredRoute>();
    /** Browser's or applicative back. */
    isBack = false;

    constructor(private location: Location) {
        this.location.subscribe(() => {
            this.isBack = true;
        });
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data.storeRoute != null && route.routeConfig != null;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const appRoute = this.getAppRoute(route);

        if (appRoute) {
            this.storedRouteHandles.set(appRoute, {
                route,
                handle,
                isBacking: false,
            });
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (route.routeConfig?.loadChildren) return false;

        const appRoute = this.getAppRoute(route);

        const stored =
            appRoute != null
                ? this.storedRouteHandles.get(appRoute) ?? null
                : null;

        if (!this.isBack && !stored?.isBacking) {
            if (stored && appRoute && this.isValidHandle(stored.handle)) {
                // We should get here when an imperative navigation is done
                // and there is an instance of the component previously stored
                stored.handle.componentRef.destroy();
                this.storedRouteHandles.delete(appRoute);
            }
            return false;
        }
        this.isBack = false;

        const storedRoute = stored ? stored.route : null;

        const shouldAttach =
            storedRoute != null
                ? route.routeConfig === storedRoute.routeConfig &&
                  route.children.length === storedRoute.children.length
                : false;

        if (appRoute && stored && shouldAttach) {
            this.storedRouteHandles.set(appRoute, {
                ...stored,
                isBacking: true,
            });
        }

        return shouldAttach;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const appRoute = this.getAppRoute(route);

        return appRoute != null
            ? this.storedRouteHandles.get(appRoute)?.handle ?? null
            : null;
    }

    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot,
    ): boolean {
        return future.routeConfig?.path === curr.routeConfig?.path;
    }

    private getAppRoute(route: ActivatedRouteSnapshot): AppRoute | null {
        const url = routeToUrl(route);
        const appRoute = url != null ? urlToAppRoute(url) : null;

        return appRoute;
    }

    private isValidHandle(
        handle: Partial<ComponentRefContainer>,
    ): handle is ComponentRefContainer {
        return handle?.componentRef != null;
    }
}
