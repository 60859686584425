import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { getRandomColor } from '@interacta-shared/util';
import { ThemeMode } from '@interacta-shared/util-common';
import { CurrentChatStatus } from '@modules/state/models/chat-state.model';

const CHAT_LOGO = './assets/images-2.0/logo_chat.svg';
const CHAT_LOGO_DARK = './assets/images-2.0/logo_chat_white.svg';

@Component({
    selector: 'interacta-chat-panel-header',
    templateUrl: './chat-panel-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPanelHeaderComponent implements OnChanges {
    @Input({ required: true }) currentChatStatus!: CurrentChatStatus;
    @Input({ required: true }) theme!: ThemeMode;
    @Input() isCollapsed = true;
    @Input() unreadChatStatus = 0;

    protected readonly getRandomColor = getRandomColor;

    readonly defaultTitle = 'interacta chat';

    chatLogo = CHAT_LOGO;
    showCurrentChat = false;
    profileImg: string | null = null;
    chatTitle = this.defaultTitle;
    unreadChatOrMessages = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.theme) {
            this.chatLogo = this.theme === 'dark' ? CHAT_LOGO_DARK : CHAT_LOGO;
        }

        if (
            changes.isCollapsed ||
            changes.currentChatStatus ||
            changes.unreadChatStatus
        ) {
            if (
                this.isCollapsed &&
                this.currentChatStatus &&
                !this.currentChatStatus.isClosed
            ) {
                this.showCurrentChat = true;
                this.chatTitle = this.currentChatStatus.title;
                this.unreadChatOrMessages =
                    this.currentChatStatus.unreadMessagesCount;

                if (this.currentChatStatus.avatarImageUrl) {
                    this.profileImg = this.currentChatStatus.avatarImageUrl;
                } else {
                    this.profileImg = null;
                }
            } else {
                this.showCurrentChat = false;
                this.chatTitle = this.defaultTitle;
                this.unreadChatOrMessages = this.unreadChatStatus;
            }
        }
    }
}
