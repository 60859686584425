<ng-container
    *ngIf="{
        user: user$ | async,
        theme: theme$ | async,
        showHeader: showHeader$ | async,
        showSidebar: showSidebar$ | async,
        canShowCarousel: canShowCarousel$ | async
    } as $"
>
    <div
        #app
        class="relative isolate flex h-screen min-h-0 w-screen flex-col overflow-hidden print:overflow-visible"
        [ngClass]="{
            snow: enableChristmasEasterEgg && $.theme?.mode === 'light'
        }"
        (scroll)="app.scrollTop = 0"
    >
        <interacta-skip-links />
        <interacta-header
            *ngIf="$.user && $.theme && $.showHeader"
            class="z-40 shrink-0"
            [currentTheme]="$.theme"
            [customerLogoSrc]="logo"
            [enableChristmasEasterEgg]="enableChristmasEasterEgg"
            [iconMode]="iconMode"
            [ngClass]="{
                snow2: enableChristmasEasterEgg && $.theme.mode === 'light'
            }"
            [user]="$.user"
            (changeLanguage)="changeLanguage($event)"
            (changeTheme)="changeTheme($event)"
            (logout)="logout()"
            (openNotificationsSettings)="openNotificationsSettings()"
            (openProfile)="openProfile()"
        />

        <div class="flex min-h-0 grow">
            <ng-container *ngIf="$.user && $.showSidebar">
                <nav
                    *ngIf="{
                        isLeftMenuOpen: (leftMenuUI$ | async)?.isOpen ?? false
                    } as sidebar$"
                    class="transition-width shrink-0 overflow-x-hidden duration-300"
                    [ngClass]="{
                        'w-252': sidebar$.isLeftMenuOpen,
                        'w-64': !sidebar$.isLeftMenuOpen
                    }"
                >
                    <interacta-sidebar
                        *ngIf="$.theme"
                        [currentTheme]="$.theme"
                        [enableChristmasEasterEgg]="enableChristmasEasterEgg"
                        [expanded]="sidebar$.isLeftMenuOpen"
                        [ngClass]="{
                            snow2:
                                enableChristmasEasterEgg &&
                                $.theme.mode === 'light'
                        }"
                    />
                </nav>
            </ng-container>
            <main class="min-w-0 grow">
                <router-outlet />
            </main>
        </div>
    </div>

    <interacta-layer />
    <interacta-confirm-dialog />
    <interacta-upload-dialog />
    <interacta-people-dialog />
    <interacta-group-info-dialog-v2 />
    <interacta-hashtag-info-dialog />
    <interacta-table-info-dialog />
    <interacta-attachments-versions-dialog />
    <interacta-bottom-menu class="fixed bottom-0 right-0 print:hidden" />
    <interacta-tip-overlay />
    <interacta-notifications-panel />
    <interacta-comment-fullscreen-editor />
    <interacta-loading-layer [src]="$.theme?.mode | appLogo" />
    <interacta-carousel-container
        [canShowCarousel]="$.canShowCarousel ?? false"
    />
    <interacta-links-dialog />
</ng-container>
