import { Injectable } from '@angular/core';
import { AuthActions, AuthService } from '@interacta-shared/data-access-auth';
import { StateManagerService } from '@modules/state/services/state-manager.service';
import { PrivateEmailService } from '@modules/verify-private-email/private-email.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { map, tap } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { fetchUserCapabilities } from './user-capabilities/user-capabilities.actions';

@Injectable()
export class AppEffects {
    afterLogin$ = createEffect(() =>
        this.actions.pipe(
            ofType(AuthActions.login),
            tap(() => {
                this.stateManagerService.initialize();
                this.googleAnalyticsService.setUserProperties(
                    this.authService.currentUserData(),
                );
                this.verifyPrivateEmailService.askVerifyCurrentUserPrivateEmail();
            }),
            map(() => fetchUserCapabilities()),
        ),
    );

    afterLogout$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.logout),
                tap(() => {
                    this.stateManagerService.flush();
                    this.googleAnalyticsService.setUserProperties(null);
                }),
            ),
        { dispatch: false },
    );

    resetFocus$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(routerNavigatedAction),
                tap((action) => {
                    if (
                        !action.payload.event.url.includes('#') &&
                        document.activeElement instanceof HTMLElement
                    ) {
                        document.activeElement.blur();
                        document.body.focus();
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions: Actions,
        private authService: AuthService,
        private stateManagerService: StateManagerService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private verifyPrivateEmailService: PrivateEmailService,
    ) {}
}
