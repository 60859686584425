<header
    *ngIf="{
        currentLanguage: currentLanguage$ | async,
        isSidebarOpen: isSidebarOpen$ | async,
        unreadNotification: (unreadNotification$ | async) ?? 0,
        newNotificationToggle: newNotificationToggle$ | async,
        notifications: notifications$ | async,
        baseRoute: baseRoute$ | async,
        showSearchBar: showSearchBar$ | async,
        rootLinkClickable: rootLinkClickable$! | async,
        currentUserClickable: currentUserClickable$ | async,
        showNotifications: showNotifications$ | async,
        homeSelectorItems: homeSelectorItems$ | async,
        currentHomeId: currentHomeId$ | async,
        chatCanShow: chatCanShow$ | async,
        hasUnreadChatNotifications: hasUnreadChatNotifications$ | async
    } as $"
    class="relative flex h-72 justify-between px-16 py-12 print:hidden"
>
    <a
        *ngIf="customerLogoSrc"
        class="absolute top-1/2"
        [attr.aria-label]="'SIDEBAR.HOME' | translate"
        [ngClass]="{ 'invisible md:visible': $.currentHomeId != null }"
        [routerLink]="$.rootLinkClickable ? ['/'] : null"
        [style.transform]="
            'translate(' +
            ($.currentHomeId != null ? 'calc( 50vw - 50%)' : '3.5rem') +
            ', -50%)'
        "
    >
        <interacta-image
            alt="Customer's logo"
            classes="h-44 object-contain"
            loading="eager"
            [fallback]="
                currentTheme.mode === 'dark' ? customerLogoSrc.light : undefined
            "
            [src]="
                currentTheme.mode === 'light'
                    ? customerLogoSrc.light
                    : customerLogoSrc.dark
            "
        ></interacta-image>
    </a>
    <div class="flex shrink-0 items-center gap-24">
        <button
            *ngIf="iconMode === 'menu'"
            interacta-icon-button
            type="button"
            [attr.aria-label]="'SIDEBAR.TOGGLE_SIDEBAR' | translate"
            [icon]="'hamburger'"
            (click)="toggleMenu()"
        ></button>
        <interacta-back *ngIf="iconMode === 'back'"></interacta-back>

        <interacta-home-selector
            *ngIf="
                $.homeSelectorItems &&
                $.homeSelectorItems.length > 0 &&
                $.currentHomeId != null
            "
            [currentHomeId]="$.currentHomeId"
            [selectableHomes]="$.homeSelectorItems"
            (changeCurrentHome)="changeCurrentHome($event)"
        ></interacta-home-selector>
    </div>
    <div class="flex items-center gap-16">
        <interacta-search *ngIf="$.showSearchBar"></interacta-search>
        <button
            interacta-icon-button
            type="button"
            [attr.aria-label]="
                'DIGITAL_WORKPLACE.OPEN_LINKS_DIALOG' | translate
            "
            [icon]="'apps'"
            [matTooltip]="'DIGITAL_WORKPLACE.OPEN_LINKS_DIALOG' | translate"
            (click)="openLinksDialog()"
        ></button>
        <div *ngIf="$.chatCanShow" class="relative flex">
            <button
                interacta-icon-button
                type="button"
                [attr.aria-label]="'CHAT_EMBEDDED.OPEN_CHAT' | translate"
                [icon]="'chat'"
                [matTooltip]="'CHAT_EMBEDDED.OPEN_CHAT' | translate"
                [matTooltipPosition]="'below'"
                (click)="toggleChat()"
            ></button>
            <div
                *ngIf="$.hasUnreadChatNotifications"
                class="bg-surface-primary border-surface-A absolute right-0 top-2 h-12 w-12 rounded-full border-2"
            ></div>
        </div>
        <ng-container *ngIf="user">
            <button
                #openMenuBtn="cdkOverlayOrigin"
                cdkOverlayOrigin
                type="button"
                [disabled]="!$.currentUserClickable"
                (click)="isUserMenuOpen = true"
            >
                <interacta-avatar
                    size="regular"
                    [enableChristmasEasterEgg]="enableChristmasEasterEgg"
                    [ngClass]="{ relative: enableChristmasEasterEgg }"
                    [user]="{
                        id: user.id,
                        fullName: user.fullName,
                        accountPhotoUrl: user.accountPhotoUrl ?? ''
                    }"
                ></interacta-avatar>
            </button>
            <interacta-user-menu
                *ngIf="$.currentLanguage && isUserMenuOpen"
                class="absolute"
                [attr.aria-expanded]="isUserMenuOpen"
                [availableLanguages]="availableLanguages"
                [currentLanguage]="$.currentLanguage"
                [currentTheme]="currentTheme"
                [currentUser]="user"
                [isOpen]="isUserMenuOpen"
                [origin]="openMenuBtn"
                (changeLanguage)="changeLanguage.emit($event)"
                (changeTheme)="changeTheme.emit($event)"
                (closing)="isUserMenuOpen = false"
                (logout)="logout.emit()"
                (openNotificationsSettings)="openNotificationsSettings.emit()"
                (openProfile)="openProfile.emit()"
            ></interacta-user-menu>
        </ng-container>

        <div *ngIf="$.showNotifications" class="relative flex">
            <button
                icon="bell"
                interacta-icon-button
                type="button"
                [active]="$.unreadNotification > 0"
                [attr.aria-label]="'NOTIFICATIONS.OPEN' | translate"
                [disabled]="
                    $.notifications?.nextPageToken?.tag === 'firstLoading' &&
                    !$.notifications?.isFetching
                "
                [ngClass]="{
                    'animate-swing origin-top':
                        $.unreadNotification && $.newNotificationToggle
                }"
                (click)="openNotificationPanel()"
            ></button>
            <div
                *ngIf="$.unreadNotification"
                class="bg-surface-primary absolute right-0 top-4 h-8 w-8 rounded-full"
            ></div>
        </div>
    </div>
</header>

<!-- print header -->
<header
    class="border-border-300 bg-text-white mb-24 hidden items-center justify-between border-b px-16 py-12 print:flex"
>
    <interacta-image
        *ngIf="customerLogoSrc"
        alt=""
        classes="h-44 object-contain"
        loading="eager"
        [src]="customerLogoSrc.light"
    ></interacta-image>
    <interacta-image
        classes="h-44"
        [alt]="'SHARED.PAGE_TITLE.DEFAULT' | translate"
        [src]="'./assets/images/logo_interacta.png'"
    ></interacta-image>
</header>
